<template>
  <div id="chair_man" :style="{backgroundImage: `url(${require('@/statics/img/about/bg.png')})`}">
    <div class="title w1200 page_header_space">
      <h3 class="txt-bold">理事長的話</h3>
      <img src="@/statics/img/index/icon_title2.svg" alt="">
    </div>

    <img class="bg_heart" src="@/statics/img/about/bg_heart.png" alt="">

    <div class="content w1200">
      <div class="col50">
        <h2 class="title">讓真正有需要的人能及時得到醫療處置</h2>
        <p class="desc">
          儘管台灣醫學發達，健保制度人人推崇，但仍存在著許多弱勢族群無法順利就醫的狀況，而居家醫療正可以解決這樣的窘境。
          「居家醫療」不僅僅只是到府看病這樣的解釋而以，而是一種醫病關係的信任，更是改善現行醫療資源浪費的方法之一，
          讓真正有需要的人能及時得到醫療處置，進而達到病患整合性之全人照顧。<br><br>
          我們已了解居家醫療不是試辦，是必需要落實執行的，我們積極配合政府推動並施行政策，但同時也會盡全力爭取醫師之權益，
          進而達到三方共贏，希望有更多同仁加入我們，攜手合作共創美好未來。
        </p>
      </div>
      <div class="col50">
        <div class="img_wrap">
          <img class="hands" src="@/statics/img/about/img_1.png" alt="">
          <img class="sign" src="@/statics/img/about/img_2.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutChairman',
  data() {
    return {

    };
  },
};
</script>
