<template>
  <div id="according">
    <div class="title w1100 page_header_space">
      <h3 class="txt-bold">『台灣居家醫療專科醫師』甄審評核實施辦法<span class="p"> 109.2.9 修訂</span></h3>
      <img src="@/statics/img/index/icon_title2.svg" alt="">
    </div>

    <section class="content">
      <div class="w1100">
        <p>一、台灣居家醫療醫學會（以下簡稱本會）為辦理居家醫療專科醫師甄審評核（以下簡稱專科醫師甄審），特訂定本實施辦法。</p>
        <div class="indent">
          <p>二、專科醫師甄審委員會〈以下簡稱本委員會〉，依台灣居家醫療學會組織章程組成。</p>
          <p class="indent_lv2">(一) 本會甄審委員會設甄審委員 3 人，任期與同屆理監事相同，並得連續受聘。</p>
          <p class="indent_lv2">(二) 本會理事長為甄審委員會之主任委員，甄審委員由主任委員提名之，並報請理監事聯席會議同意後聘任之。</p>
          <p class="indent_lv2">(三) 甄審委員之職責為<br>
            1.審核申請甄審者之學經歷。<br>
            2.辦理專科醫師之各項甄審、命題、及評分。<br>
            3.監理專科醫師證書有效期限之展延工作。<br>
            4.其他有關甄審之工作。<br>
          </p>
        </div>
        <div class="indent">
          <p>三、凡申請專科醫師甄審者需具備下列必要條件：</p>
          <p class="indent_lv2">(一) 醫師需有西醫師任何科別之專科醫師證照，或無專科醫師且西醫執業登記滿 4 年以上</p>
          <p class="indent_lv2">(二) 需為台灣居家醫療醫學會會員</p>
          <div class="indent indent_lv2">
            <p>(三) 下列三條件之一：</p>
            <p class="indent_lv3">
              1.凡是施做居家醫療滿二年以上且個案超過 15 人以上者(須提供VPN名單)，且領各種專科醫師執照執業滿 4 年者，
              皆可於 109 年 12 月 31 日前備妥資料，直接向本醫學會申請台灣居家醫療專科醫師證書。
            </p>
            <p class="indent_lv3">
              2.必須有台灣居家醫療醫學會認可之學術含實習 60 小時課程(本課程內容如附件)。
              凡於 110 年 12 月 31 日修得全部課程，提出申請並繳費後，經本醫學會認證合格者免筆試，即可得居家醫療專科醫師。
            </p>
            <p class="indent_lv3">
              3.民國 111 年 1 月 1 日以後甄審者需筆試。資格審查及筆試均及格者為合格。資格審查或筆試任一項目未合格者，
              其資格成績得保留四年，逾期需重新申請與完成筆試測驗。
            </p>
          </div>
        </div>
        <p>
          四、專科醫師甄審筆試成績採百分法計算，筆試成績以六十分為及格。筆試採用選擇題，中文或英文命題（專有名詞部分得用英文
          ；其內容範圍，以居家醫學核心課程相關學科領域為依據，由教育學術委員會訂定公告之。
        </p>
        <p>五、專科醫師甄審每年辦理一次，其時間以及實施辦法，由本會秘書處公告之。</p>
        <p>六、參加專科醫師甄審，以通信、網路或親自報名方式為之。</p>
        <div class="indent">
          <p>七、參加專科醫師甄審，應繳交下列表件及證明文件：</p>
          <p class="indent_lv2">(一)申請表、個人資料使用同意書。</p>
          <p class="indent_lv2">(二)醫師證書正反面影本。</p>
          <p class="indent_lv2">(三)醫師執業執照影本。</p>
          <p class="indent_lv2">(四)醫師專科醫師證書影本。</p>
          <p class="indent_lv2">(五)最近一年內二吋正面脫帽半身照片兩張。</p>
          <p class="indent_lv2">(六)專科證書審查費用：新台幣壹萬元整。</p>
          <p class="indent_lv2">(七)其他有關證明文件。</p>
        </div>
        <p>
          八、本會『居家醫療醫學專科醫師』證書有效期限為六年，期滿每次展延期限為六年。申請專科醫師證書有效期限展延，
          應於專科醫師證書之有效期限六年內，參加符合本會專科醫師甄審委員會規定之學術活動或繼續教育課程積分總分達 180 個學分
          以上者方可辦理展延，其中甲類 140 點必須參加由居家醫療醫學會主辦之研討活動；乙類 40 點則由居家醫療醫學會協辦或認可之研討活動。
          （甲類學分可抵扣乙類學分，但乙類學分不能折抵甲類學分）；逾期未達學分數者，需於 1 年內補修滿 180 學分方可換證，
          且補修學分不計入新證書有效期限。
        </p>
        <div class="indent">
          <p>九、申請專科醫師證書有效期限展延，應於公告之期限內，以網路或親自申請方式為之，並繳交下列表件：</p>
          <p class="indent_lv2">(一)申請表。</p>
          <p class="indent_lv2">(二)符合第九點所訂展延條件之各項證明文件。</p>
          <p class="indent_lv2">(三)最近一年內二吋正面脫帽半身照片二張。</p>
          <p class="indent_lv2">(四)證書展延費用：新台幣貳千元整。</p>
          <p class="indent_lv2">(五)其他有關證明文件。</p>
        </div>
        <p>十、專科醫師甄審或專科醫師證書有效期限展延，實際收取費用依本會理監事會議決議辦理。</p>
        <p class="indent_lv2">
          十一、專科醫師甄審考試成績得申請複查，但應於當年度以書面敘明理由向本會申請，逾期不予受理，
          並以本人申請及複查一次為限。前項複查，不得要求重新評閱試卷、提供答案、閱讀或影印試卷，
          亦不得要求告知命題或閱卷人員之姓名或其他相關資料。
        </p>
        <p class="indent_lv2">
          十二、專科醫師甄審或專科醫師證書有效期限展延之有關試卷、論著及資格證明文件等資料，
          除留供研究者外，保存二年。但保留筆試及格成績補行資格審查者，保存四年。
        </p>
        <!-- <div class="btn_wrap">
          <router-link to="/account/apply/profession" class="btn">專科申請</router-link>
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutAccording',
  data() {
    return {

    };
  },
};
</script>
