<template>
  <div class="home">
    <section id="kv">
      <div class="kv_box">

        <!-- 主要圖片 -->
        <div class="kv_img w1600">
          <div class="col70 right">
            <img src="@/statics/img/index/kv_img.png" alt="">
          </div>
        </div>

        <!-- KV文字+球球裝飾底圖 -->
        <div class="kv_bg_deco w1600">
          <div class="col50">
            <img src="@/statics/img/index/kv_deco.png" alt="">
            <div class="kv_content w1000">
              <div class="title">
                <h1 class="txt-light_green">Association of<br>Home Care</h1>
                <h2>守護高齡社會的寧靜革命</h2>
                <router-link to="/about" class="btn">About</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="home_care">
      <div class="bg-wrap">
        <img src="@/statics/img/index/deco_heart.png" class="bg" alt="" >
      </div>
      <!-- title -->
      <div class="intro">
        <div class="w1400">
          <h3 class="txt-bold">Home Care 認識居家醫療</h3>
          <div class="heart-hr">
            <img class="svg" src="@/statics/img/index/icon_title.svg" alt="">
          </div>
          <h2>讓年邁、失能的病患也能擁有平等的就醫權益</h2>
        </div>
      </div>
      <!-- content -->
      <div class="wrap">
        <div class="w1400">
          <div class="down">
            <div id="home_care_area" class="showbox active">
              <!-- 主要內文區 -->
              <div class="stepinfo">

                <div
                  class="stepinfo_item"
                  v-for="(stepInfoItem) in stepInfoItemArray"
                  :class="{active: stepInfoItem.isActive}"
                  :key="stepInfoItem.id"
                >
                  <div class="content">
                    <div class="left">
                      <img :src="stepInfoItem.leftImg" alt="">
                      <div class="text-img-mb">
                        <img
                          class="svg"
                          :src="stepInfoItem.textImgMb"
                          alt=""
                        >
                      </div>
                    </div>
                    <div class="right">
                      <img
                        class="stepicon"
                        :src="stepInfoItem.stepIcon"
                        alt=""
                      >
                      <h3>{{ stepInfoItem.title}}</h3>
                      <p v-html="stepInfoItem.content"></p>
                    </div>
                    <div class="text-img">
                      <img :src="stepInfoItem.textImg" alt="">
                    </div>
                  </div>
                </div>

                <!-- <div class="stepinfo_item active">
                  <div class="content">
                    <div class="left">
                      <img src="@/statics/img/index/img_1.png" alt="">
                      <div class="text-img-mb">
                        <img class="svg" src="@/statics/img/index/1-trust.svg" alt="">
                      </div>
                    </div>
                    <div class="right">
                      <img class="stepicon" src="@/statics/img/index/icon_1.svg" alt="">
                      <h3>為什麼台灣需要居家醫療？</h3>
                      <p>自從居家醫療團隊介入之後，與家屬建立信任關係，當阿嬤出現狀況，家屬就先詢問家庭醫師。
                        慢慢地，家屬也學著自行處理，減少舟車勞頓送醫次數，也大幅降低醫療時間和金錢成本。</p>
                    </div>
                    <div class="text-img">
                      <img src="@/statics/img/index/1-trust.png" alt="">
                    </div>
                  </div>
                </div> -->
                <!-- <div class="stepinfo_item" :class="{active: isActive}">
                  <div class="content">
                    <div class="left">
                      <img src="@/statics/img/index/img_2.png" alt="">
                      <div class="text-img-mb">
                        <img class="svg" src="@/statics/img/index/2-warm.svg" alt="">
                      </div>
                    </div>
                    <div class="right">
                      <img class="stepicon" src="@/statics/img/index/icon_2.svg" alt="">
                      <h3>使用居家醫療的服務條件</h3>
                      <p>
                        揪肝心醫生也能到家裡來看我 服務對象：
                        <br>
                        <span class="circle_number">
                          <span class="text small">1</span>
                        </span>失能或疾病而外出不便。
                        <br>
                        <span class="circle_number">
                          <span class="text small">2</span>
                        </span>有明確醫療需求。
                        <br>
                        <span class="circle_number">
                          <span class="text small">3</span>
                        </span>居住於家中。
                      </p>
                    </div>
                    <div class="text-img">
                      <img src="@/statics/img/index/2-warm.png" alt="">
                    </div>
                  </div>
                </div> -->
                <!-- <div class="stepinfo_item" :class="{active: isActive}">
                  <div class="content">
                    <div class="left">
                      <img src="@/statics/img/index/img_3.png" alt="">
                      <div class="text-img-mb">
                        <img class="svg" src="@/statics/img/index/3-service.svg" alt="">
                      </div>
                    </div>
                    <div class="right">
                      <img class="stepicon" src="@/statics/img/index/icon_3.svg" alt="">
                      <h3>居家醫療照護整合計畫</h3>
                      <p>本計畫整合一般居家照護、呼吸居家照護、安寧居家療護及居家醫療試辦計畫4項服務，為3照護階段，
                        鼓勵院所組成整合性照護團隊，強化醫療照護資源連結轉介，提供以病人為中心之整合照護。</p>
                    </div>
                    <div class="text-img">
                      <img src="@/statics/img/index/3-service.png" alt="">
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- 進度條 -->
              <div class="stepbar">
                <div
                  class="step"
                  :class="{active: stepBar.step[0].isActive}"
                  :data-index="stepBar.step[0].dataIndex"
                  @click="stepActive(stepBar.step[0].dataIndex)"
                >
                  <div class="number">
                    <p>1</p>
                  </div>
                </div>
                <div class="stepgap">
                  <div
                    class="stepgap-progress"
                    :class="{active: stepBar.gap[0].isActive}"
                    :style="stepBar.gap[0].styleObj"
                  ></div>
                </div>
                <div
                  class="step"
                  :class="{active: stepBar.step[1].isActive}"
                  :data-index="stepBar.step[1].dataIndex"
                  @click="stepActive(stepBar.step[1].dataIndex)"
                >
                  <div class="number">
                    <p>2</p>
                  </div>
                </div>
                <div class="stepgap">
                  <div
                    class="stepgap-progress"
                    :class="{active: stepBar.gap[1].isActive}"
                    :style="stepBar.gap[1].styleObj"
                  ></div>
                </div>
                <div
                  class="step"
                  :class="{active: stepBar.step[2].isActive}"
                  :data-index="stepBar.step[2].dataIndex"
                  @click="stepActive(stepBar.step[2].dataIndex)"
                >
                  <div class="number">
                    <p>3</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 學會訊息 -->
    <section id="news">
      <div class="w1200">
        <div class="title">
          <h3 class="txt-light_green txt-bold">INFORMATION 學會訊息</h3>
          <img class="svg" src="@/statics/img/index/icon_title2.svg" alt="">
        </div>
        <div class="content">
          <!-- 左 文章區 -->
          <div class="col50">
            <div class="arrow_wrapper_l">
              <img
                class="svg mb_arrow mb_arrow_l"
                src="@/statics/img/index/icon_arrow_r.svg"
                alt="">
            </div>
            <div class="arrow_wrapper_r">
              <img
                class="svg mb_arrow mb_arrow_r"
                src="@/statics/img/index/icon_arrow_r.svg"
                alt="">
            </div>
            <div class="indexNews_box">

              <!-- 索引 -->
              <div class="main_tab_box">
                <ul class="main_tab txt-bold">
                  <li
                    v-for="(newsTab, index) in computedNewsTabList"
                    :key="newsTab.id"
                    :class="{ active: index === newsTabIndex }"
                    @click="filterNews(newsTab.id, index)"
                  >
                    {{ newsTab.name }}
                  </li>
                </ul>
              </div>

              <!-- 文章列表 -->
              <div class="contain_box" id="Newsdata_List">
                <ul>

                  <!-- 被置頂的文章 class: sticky -->
                  <li
                    v-for="newsArticle in newsArticleArray"
                    :key="newsArticle.uuid"
                    :class="{'sticky': newsArticle.sticky}"
                  >
                    <router-link
                      :to="`/news/${newsArticle.sys_name}/article?news_id=${newsArticle.uuid}`"
                    >
                      <div class="flex-nowrap">
                        <div class="front-area">
                          <p
                            class="date"
                            :class="{ 'txt-red': parseInt(newsArticle.top, 10) === 1 }"
                          >{{ postDate(newsArticle) }}</p>
                          <span class="type">
                            <small>{{ newsArticle.sys_name }}</small>
                          </span>
                        </div>
                        <p class="article">{{ newsArticle.title }}</p>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 按鈕 -->
            <div class="btn_wrap">
              <router-link to="/news/全部" class="btn">MORE</router-link>
            </div>
          </div>
          <!-- 右 圖片區 -->
          <div class="col50">
            <div class="img-wrap">
              <img class="img-l" src="@/statics/img/index/img_4.png" alt="">
              <img class="img-r" src="@/statics/img/index/img_5.png" alt="">
              <img class="img-deco" src="@/statics/img/index/deco_home.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 活動報名
    <section id="event">
      <div class="w1200">
        <div class="title">
          <h3 class="txt-light_green txt-bold">ACTIVITIES 國內學術活動</h3>
          <img class="svg" src="@/statics/img/index/icon_title2.svg" alt="">
        </div>
        <div class="content">
          左 月曆
          <div class="col50">
            <v-calendar
              class="vc-index"
              is-expanded
              :attributes="computedVcAttributes"
            ></v-calendar>
          </div>
          右 列表
          <div class="col50">
            <ul class="event_list">
              <li v-for="event in eventArray" :key="event.uuid">
                  <router-link
                    :to="`/register/event/info?event_id=${event.uuid}`"
                    class="cards_item"
                  >
                    <div class="wrap">
                      <div class="col20">
                        <div class="data_wrap">
                          <h4 class="ym">
                            {{ eventYear(event.start_date) }}
                              . {{ eventMonth(event.start_date) }}
                          </h4>
                          <h2 class="d">{{ eventDay(event.start_date) }}</h2>
                        </div>
                      </div>
                      <div class="col80">
                        <p class="card_title">{{ event.name }}</p>
                        <p class="card_host small">主辦單位：{{ event.host }}</p>
                        <p class="card_fraction small type-A" v-if="event.fraction_a">
                          <span class="tag">A類</span>
                          <span class="credit">．{{ event.fraction_a }}學分</span>
                        </p>
                        <p class="card_fraction small type-B" v-if="event.fraction_b">
                          <span class="tag">B類</span>
                          <span class="credit">．{{ event.fraction_b }}學分</span>
                        </p>
                        <p class="location ls1">{{ event.place }}</p>
                        <<<<<<<< comment start
                        <p class="time ls1">
                          {{ activity.time.start }} - {{ activity.time.end }}
                          ({{ activity.time.duration }} hours)
                        </p>
                        >>>>>>>> comment end
                      </div>
                    </div>
                  </router-link>
                </li>
            </ul>
          </div>
        </div>
        <div class="btn_wrap">
          <router-link to="/register/event" class="btn">more</router-link>
        </div>
      </div>
    </section> -->

    <section id="AD">
      <img class="AD_pc" src="@/statics/img/index/slogn_bg.png" alt="">
      <img class="AD_mb" src="@/statics/img/index/slogn_bg_m.png" alt="">
      <div class="text">
        <div class="w1400 wrap">
          <div class="col50">
            <h3 class="txt-white">Association of Home Care
              <img src="@/statics/img/index/button_heart2.svg" alt="">
            </h3>
            <h2>給老人完善的居家醫療照顧<br>民眾就醫和臨終觀念改變以及社區互助精神</h2>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import '@/assets/scss/index.scss';
import moment from 'moment';
import {
  articleIndex,
  // eventsIndex,
  calendar,
} from '@/lib/http';
import { mapGetters } from 'vuex';

import leftImg1 from '../statics/img/index/img_1.png';
import stepIcon1 from '../statics/img/index/icon_1.svg';
import textImg1 from '../statics/img/index/1-trust.png';
import leftImgMb1 from '../statics/img/index/1-trust.svg';
import leftImg2 from '../statics/img/index/img_2.png';
import stepIcon2 from '../statics/img/index/icon_2.svg';
import textImg2 from '../statics/img/index/2-warm.png';
import leftImgMb2 from '../statics/img/index/2-warm.svg';
import leftImg3 from '../statics/img/index/img_3.png';
import stepIcon3 from '../statics/img/index/icon_3.svg';
import textImg3 from '../statics/img/index/3-service.png';
import leftImgMb3 from '../statics/img/index/3-service.svg';

export default {
  title: '首頁',
  name: 'Home',
  data() {
    return {
      stepInfoItemArray: [
        {
          id: 1,
          title: '為什麼台灣需要居家醫療？',
          content: '自從居家醫療團隊介入之後，與家屬建立信任關係，當阿嬤出現狀況，家屬就先詢問家庭醫師。慢慢地，家屬也學著自行處理，減少舟車勞頓送醫次數，也大幅降低醫療時間和金錢成本。',
          leftImg: leftImg1,
          stepIcon: stepIcon1,
          textImg: textImg1,
          textImgMb: leftImgMb1,
          isActive: true,
        },
        {
          id: 2,
          title: '使用居家醫療的服務條件',
          content: '揪肝心醫生也能到家裡來看我 服務對象：<br><span class="circle_number"><span class="text small">1</span></span>失能或疾病而外出不便。<br><span class="circle_number"><span class="text small">2</span></span>有明確醫療需求。<br><span class="circle_number"><span class="text small">3</span></span>居住於家中。',
          leftImg: leftImg2,
          stepIcon: stepIcon2,
          textImg: textImg2,
          textImgMb: leftImgMb2,
          isActive: false,
        },
        {
          id: 3,
          title: '居家醫療照護整合計畫',
          content: '本計畫整合一般居家照護、呼吸居家照護、安寧居家療護及居家醫療試辦計畫4項服務，為3照護階段，鼓勵院所組成整合性照護團隊，強化醫療照護資源連結轉介，提供以病人為中心之整合照護。',
          leftImg: leftImg3,
          stepIcon: stepIcon3,
          textImg: textImg3,
          textImgMb: leftImgMb3,
          isActive: false,
        },
      ],
      stepBar: {
        countStep: undefined,
        stepKeepTime: 2500,
        homeCareStart: false,
        step: [
          {
            id: 1,
            isActive: true,
            dataIndex: 0,
          },
          {
            id: 2,
            isActive: false,
            dataIndex: 1,
          },
          {
            id: 3,
            isActive: false,
            dataIndex: 2,
          },
        ],
        gap: [
          {
            id: 1,
            isActive: false,
            styleObj: {
              width: '0%',
            },
          },
          {
            id: 2,
            isActive: false,
            styleObj: {
              width: '0%',
            },
          },
        ],
      },
      newsTabList: [
        {
          id: 1,
          name: '全部',
          type: 'all',
        },
        // {
        //   id: 2,
        //   name: '相關公文',
        //   type: 'relative',
        // },
        // {
        //   id: 3,
        //   name: '論文刊物',
        //   type: 'paper',
        // },
        // {
        //   id: 4,
        //   name: '健保資訊',
        //   type: 'health',
        // },
        // {
        //   id: 5,
        //   name: '新聞焦點',
        //   type: 'focus',
        // },
        // {
        //   id: 6,
        //   name: '活動花絮',
        //   type: 'activity',
        // },
      ],
      newsTabIndex: 0,
      newsArticleArray: [
        // {
        //   id: 1,
        //   sticky: true,
        //   date: '2020-02-27',
        //   type: 'relative',
        //   content: '本會預計3/1(日)將轉換系統網站 !本會預計3/1(日)將轉換系統網站 !本會預計3/1(日)將轉換系統網站 !',
        // },
        // {
        //   id: 2,
        //   sticky: false,
        //   date: '2020-02-27',
        //   type: 'paper',
        //   content: '本會預計3/1(日)將轉換系統網站 !本會預計3/1(日)將轉換系統網站 !本會預計3/1(日)將轉換系統網站 !',
        // },
        // {
        //   id: 3,
        //   sticky: false,
        //   date: '2020-02-27',
        //   type: 'health',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 4,
        //   sticky: false,
        //   date: '2020-02-27',
        //   type: 'focus',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 5,
        //   sticky: false,
        //   date: '2020-02-27',
        //   type: 'activity',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 6,
        //   sticky: false,
        //   date: '2020-02-27',
        //   type: 'relative',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
      ],
      vcAttributes: [
        {
          key: 'today',
          highlight: {
            class: 'highlight',
            contentStyle: { color: '#fff' },
          },
          dates: new Date(),
        },
        /** vue-calendar format */
        // {
        //   dot: {
        //     class: 'dot dot-A',
        //   },
        //   dates: [
        //     new Date('2020-12-31'),
        //   ],
        // },
      ],
      eventArray: [
        // {
        //   id: 1,
        //   url: '/register/event',
        //   title: '乙類安寧居家療護 - 109年度社區安寧照護(乙類)醫護人員 教育訓練教育訓練教育訓練教育訓練教育訓練教育訓練教育訓練教育訓練',
        //   host: '台灣居家醫療醫學會',
        //   tag: 'A',
        //   credit: '3',
        //   location: '中山醫學大學 - 正心樓0225教室',
        //   date: '2021-01-05',
        //   time: {
        //     start: '10:00',
        //     end: '16:00',
        //     duration: '6',
        //   },
        // },
        // {
        //   id: 2,
        //   url: '/register/event',
        //   title: '乙類安寧居家療護',
        //   host: '台灣居家醫療醫學會',
        //   tag: 'B',
        //   credit: '3',
        //   location: '中山醫學大學 - 正心樓0225教室',
        //   date: '2021-01-11',
        //   time: {
        //     start: '10:00',
        //     end: '16:00',
        //     duration: '6',
        //   },
        // },
        // {
        //   id: 3,
        //   url: '/register/event',
        //   title: '乙類安寧居家療護',
        //   host: '台灣居家醫療醫學會',
        //   tag: 'A',
        //   credit: '3',
        //   location: '中山醫學大學 - 正心樓0225教室',
        //   date: '2021-01-20',
        //   time: {
        //     start: '10:00',
        //     end: '16:00',
        //     duration: '6',
        //   },
        // },
        // {
        //   id: 4,
        //   url: '/register/event',
        //   title: '乙類安寧居家療護',
        //   host: '台灣居家醫療醫學會',
        //   tag: 'B',
        //   credit: '3',
        //   location: '中山醫學大學 - 正心樓0225教室',
        //   date: '2021-01-25',
        //   time: {
        //     start: '10:00',
        //     end: '16:00',
        //     duration: '6',
        //   },
        // },
      ],
      homeCareStart: false,
    };
  },
  created() {
    /**
     * 學會訊息
     */
    articleIndex({
      type: 'news',
    }).then((result) => {
      this.newsArticleArray = result.data;
    });
    /**
     * 國內學術活動
     */
    calendar({ date: moment().format('YYYY-MM') }).then((result) => {
      this.eventArray = result.data.result.data;
    });

    // const monthStart = moment().startOf('month').format('YYYY-MM-DD');
    // const monthEnd = moment().endOf('month').format('YYYY-MM-DD');

    // eventsIndex({
    //   start_date: monthStart,
    //   end_date: monthEnd,
    // }).then((result) => {
    //   console.log('event', result);
    //   this.eventArray = result.data;
    // });
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  computed: {
    ...mapGetters([
      'newsType',
    ]),
    computedNewsTabList() {
      return [...this.newsTabList, ...this.newsType];
    },
    computedVcAttributes() {
      const vcAttributesCopy = this.vcAttributes;
      this.eventArray.forEach((event) => {
        const dotObj = {
          dot: {
            // class: `dot dot-${event.tag}`,
            class: 'dot dot-A',
          },
          dates: [new Date(event.start_date)],
        };
        vcAttributesCopy.push(dotObj);
      });
      return vcAttributesCopy;
    },
  },
  methods: {
    stepRun(setTime, ifAnimation) {
      let nowStep = 0;

      // reset all
      this.stepBar.countStep = undefined;
      this.stepBar.step[0].isActive = true;
      this.stepBar.step[1].isActive = false;
      this.stepBar.step[2].isActive = false;
      this.stepBar.gap[0].isActive = false;
      this.stepBar.gap[1].isActive = false;
      this.stepInfoItemArray[0].isActive = true;

      // 是否定時切換步驟
      if (ifAnimation) {
        // 設定進度條動畫時間，開啟第一段動畫
        this.stepBar.gap[0].isActive = true;
        this.stepBar.gap[0].styleObj.animationDuration = `${setTime / 1000}s`;

        // 定時器
        this.stepBar.countStep = setInterval(() => {
          if (nowStep < this.stepBar.gap.length) {
            nowStep += 1;

            this.stepBar.step[0].isActive = false;
            this.stepBar.step[1].isActive = false;
            this.stepBar.step[2].isActive = false;
            this.stepBar.step[nowStep].isActive = true;

            this.stepInfoItemArray[0].isActive = false;
            this.stepInfoItemArray[1].isActive = false;
            this.stepInfoItemArray[2].isActive = false;
            this.stepInfoItemArray[nowStep].isActive = true;

            if (nowStep < this.stepBar.gap.length) {
              this.stepBar.gap[nowStep].isActive = true;
              this.stepBar.gap[nowStep].styleObj.animationDuration = `${setTime / 1000}s`;
            }
          } else {
            this.stepBar.countStep = undefined;
          }
        }, setTime);
      }
    },
    stepActive(index) {
      this.stepBar.countStep = undefined;
      this.stepBar.step[0].isActive = false;
      this.stepBar.step[1].isActive = false;
      this.stepBar.step[2].isActive = false;
      this.stepBar.step[index].isActive = true;

      this.stepBar.gap[0].isActive = false;
      this.stepBar.gap[1].isActive = false;
      this.stepBar.gap[0].styleObj.animationDuration = '0s';
      this.stepBar.gap[1].styleObj.animationDuration = '0s';

      switch (index) {
        case 0:
          break;
        case 1:
          this.stepBar.gap[index - 1].isActive = false;
          this.stepBar.gap[index - 1].isActive = true;
          break;
        case 2:
          this.stepBar.gap[index - 2].isActive = true;
          this.stepBar.gap[index - 1].isActive = true;
          break;
        default:
          break;
      }

      this.stepInfoItemArray[0].isActive = false;
      this.stepInfoItemArray[1].isActive = false;
      this.stepInfoItemArray[2].isActive = false;
      this.stepInfoItemArray[index].isActive = true;
    },
    scrollHandler() {
      if (document.querySelector('#home_care_area').getBoundingClientRect().top < 500
        && !this.stepBar.homeCareStart) {
        this.stepRun(this.stepBar.stepKeepTime, true);
        this.stepBar.homeCareStart = true;
      }
    },
    translateType(type) {
      let translate = '';
      this.newsTabList.forEach((newsTab) => {
        if (newsTab.type === type) {
          translate = newsTab.name;
        }
      });
      return translate;
    },
    postDate(row) {
      return row.start_time.split(' ')[0] || '';
    },
    filterNews(id, index) {
      this.newsTabIndex = index;
      if (parseInt(id, 10) !== 1) {
        articleIndex({
          sys_var_id: id,
          type: 'news',
        }).then((result) => {
          this.newsArticleArray = result.data;
        });
      } else {
        articleIndex({
          type: 'news',
        }).then((result) => {
          this.newsArticleArray = result.data;
        });
      }
    },
    eventYear(date) {
      let y = '';
      if (date) {
        // eslint-disable-next-line prefer-destructuring
        y = date.split('T')[0].split('-')[0];
      }
      return y;
    },
    eventMonth(date) {
      let m = '';
      if (date) {
        // eslint-disable-next-line prefer-destructuring
        m = date.split('T')[0].split('-')[1];
      }
      return m;
    },
    eventDay(date) {
      let d = '';
      if (date) {
        // eslint-disable-next-line prefer-destructuring
        d = date.split('T')[0].split('-')[2];
      }
      return d;
    },
  },
};
</script>
