<template>
  <div
    class="register_modal_container"
    :class="{active: openModalIdentity}"
    @click="closeModal"
  >
    <div class="register_modal txt-center" @click.stop>
      <div class="close_btn h2" @click="closeModal"><span>×</span></div>
      <div class="register_modal_member">
        <h4 class="modal_title txt-bold">本會會員登入</h4>
        <button
          class="btn"
          @click="memberLogin"
        >登入</button>
      </div>
      <div class="register_modal_nonMember">
        <div class="form_box">
          <form id="form_register_modal_nonMember" @submit.prevent>

            <div class="form_description">
              <h4 class="modal_title txt-bold">非本會會員</h4>
            </div>
            <div class="form_interactive">

              <!-- 課程活動：其他入口 -->
              <ModalTextInput
                class="w50"
                v-model.trim="user.id_number"
                :item="inputFormat.idInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  [/^[A-Z][1-2]\d{8}$/, '身分證格式錯誤']
                ]"
                :maxlength="10"
              />

            </div>
            <div class="form_submit">
              <button
                type="button"
                class="btn p"
                id="send_btn_verify"
                @click="nonMemberLogin"
              >送出</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalTextInput from '@/components/form/ModalTextInput.vue';

import { mapMutations, mapGetters, mapActions } from 'vuex';
import VueRouter from 'vue-router';
import { setAxiosHeaders } from '@/boot/axios';
import { login } from '@/lib/http';

// eslint-disable-next-line no-unused-vars
const { isNavigationFailure, NavigationFailureType } = VueRouter;

export default {
  name: 'ModalIdentity',
  data() {
    return {
      user: {
        id_number: '',
      },
      inputFormat: {
        idInput: {
          label: '',
          type: 'text',
          placeholder: '請輸入身分證字號',
          required: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'openModalIdentity',
    ]),
  },
  methods: {
    ...mapActions([
      'login',
    ]),
    ...mapMutations([
      'toggleModalIdentity',
      'toggleModalInfo',
      'updateModalInfoContent',
    ]),
    closeModal() {
      this.toggleModalIdentity(false);
    },
    memberLogin() {
      this.closeModal();
      this.$router.push({ path: '/login', query: { redirect: '/register/online/mycourse' } });
    },
    nonMemberLogin() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Modal/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        login({
          id_number: this.user.id_number,
          password: this.user.id_number,
        }).then((result) => {
          if (result.data.status) {
            this.login(result.data.result.user);
            setAxiosHeaders(this.$cookies.get('token'));
            if (this.$route.query.redirect) {
              this.$router.push({ path: this.$route.query.redirect })
                // eslint-disable-next-line no-unused-vars
                .catch((failure) => {
                  // console.log(isNavigationFailure(failure, NavigationFailureType.redirected));
                });
            } else {
              this.$router.push({ path: '/' });
            }
            this.closeModal();
            this.user.id_number = '';
          } else {
            this.updateModalInfoContent(result.data.message);
            this.toggleModalInfo(true);
          }
        });
      } else {
        this.updateModalInfoContent('必填欄位尚未完成！');
        this.toggleModalInfo(true);
      }
    },
  },
  components: {
    ModalTextInput,
  },
};
</script>
