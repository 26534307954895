<template>
  <div id="member_list">
    <div class="title w1100 page_header_space">
      <h3 class="txt-bold">監理事名單</h3>
      <img src="@/statics/img/index/icon_title2.svg" alt="">
    </div>

    <section class="table w900">
      <table style="width:100%">
        <tr>
          <th>職務</th>
          <th>姓名</th>
          <th>服務單位</th>
        </tr>
        <tr>
          <td class="position">理事長</td>
          <td class="name">郭啓昭</td>
          <td class="service_location small">台中東山聯合診所(弘光診所)</td>
        </tr>
        <tr>
          <td rowspan="2" class="position">副理事長</td>
          <td class="name">楊昆澈</td>
          <td class="service_location small">台大醫院北護分院家醫科</td>
        </tr>
        <tr>
          <td class="name">林恒立</td>
          <td class="service_location small">林恆立耳鼻喉科診所</td>
        </tr>
        <tr>
          <td rowspan="6" class="position">理事</td>
          <td class="name">孫文榮</td>
          <td class="service_location small">台北市立聯合醫院家醫科</td>
        </tr>
        <tr>
          <td class="name">黃子育</td>
          <td class="service_location small">黃子育診所</td>
        </tr>
        <tr>
          <td class="name">方光宗</td>
          <td class="service_location small">哈佛診所</td>
        </tr>
        <tr>
          <td class="name">王維弘</td>
          <td class="service_location small">維弘復健科診所</td>
        </tr>
        <tr>
          <td class="name">江錫輝</td>
          <td class="service_location small">江錫輝診所</td>
        </tr>
        <tr>
          <td class="name">方修強</td>
          <td class="service_location small">新博愛診所</td>
        </tr>
        <tr>
          <td class="position">候補理事</td>
          <td class="name">陳先正</td>
          <td class="service_location small">正生婦幼聯合診所</td>
        </tr>
        <tr>
          <td class="position">常務監事</td>
          <td class="name">黃啟明</td>
          <td class="service_location small">黃啓明診所</td>
        </tr>
        <tr>
          <td rowspan="2" class="position">監事</td>
          <td class="name">周真玲</td>
          <td class="service_location small">台中東山聯合診所(東山家庭醫學科診所)</td>
        </tr>
        <tr>
          <td class="name">紀乃正</td>
          <td class="service_location small">紀醫師診所</td>
        </tr>
        <tr>
          <td class="position">候補監事</td>
          <td class="name">宋的祥</td>
          <td class="service_location small">宋的祥診所</td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutMemberList',
  data() {
    return {

    };
  },
};
</script>
