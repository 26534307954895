<template>

  <!-- kv -->
  <section
    id="kv_content"
    :style="{ backgroundImage: `url( ${pageHeader.kvImg} )` }">
    <div class="kv_title">
      <h1 :class="kvTitle1Color">{{ pageHeader.kvTitle1 }}</h1>
      <h2 :class="kvTitle2Color">{{ pageHeader.kvTitle2 }}</h2>
    </div>

    <!-- page header -->
    <div class="page_header layout_container">
      <ul class="page_nav">

        <!-- overflow arrows -->
        <div class="arrow_wrapper_l">
          <img class="mb_arrow mb_arrow_l" src="@/statics/img/index/icon_arrow_r.svg" alt="">
        </div>
        <div class="arrow_wrapper_r">
          <img class="mb_arrow mb_arrow_r" src="@/statics/img/index/icon_arrow_r.svg" alt="">
        </div>

        <!-- page header list -->
        <!-- <li
          v-for="(item, index) in pageHeader.listArray"
          :key="item.id"
          :class="{ active: pageListActiveByTabName(index, item.text) }"
          @click="pageListActive(item.text)"
        > -->
        <li
          v-for="item in computedListArray.listArray"
          :key="item.id"
          :class="{
            active: item.id === 1 && $route.path === `/${pageHeader.name}`,
            newsTypeActive: $route.params.type === item.text
          }"
          @click="pageListActive(item.text, item.text)"
        >
          <!-- <router-link
            :to="item.url"
            :class="{
              isFixedCourse: $route.query.course_id === '5fe9501830a05' && item.text === '線上課程'
            }"
          > -->
          <!-- <router-link
            :to="item.url"
            :class="{
              forceDisable: item.text === '安寧課程'
            }"
          > -->
          <router-link
            :to="item.url"
          >
            <h4 class="txt-bold">{{ item.text }}</h4>
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'PageHeader',
  props: {
    pageHeader: Object,
    kvTitle1Color: String,
    kvTitle2Color: String,
    newsOnly: Boolean,
  },
  data() {
    return {
      resetTab: 0,
    };
  },
  computed: {
    ...mapState([
      'level',
      'currentTabName',
      'newsPageType',
    ]),
    /**
     * 會員專區重組(待刪除功能)：相關查詢其餘頁籤未開發，除了level-1會員外先隱藏
     */
    computedListArray() {
      if (this.pageHeader) {
        const copyListArray = [];
        this.pageHeader.listArray.forEach((item) => {
          if (item.text === '相關查詢' && parseInt(this.level, 10) !== 1) {
            return;
          }
          copyListArray.push(item);
        });
        return { ...this.pageHeader, listArray: copyListArray };
      }
      return {};
    },
  },
  methods: {
    ...mapMutations([
      'updateNewsType',
      'updateTabName',
    ]),
    pageListActiveByTabName(index, listName) {
      if (this.currentTabName) {
        return this.currentTabName === listName;
      }
      return this.resetTab === index;
    },
    pageListActive(listName, newsType) {
      this.updateTabName(listName);
      if (this.newsOnly) {
        this.updateNewsType(newsType);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.newsTypeActive {
  color: #57bdbd;
}
</style>
