import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { setAxiosHeaders } from '@/boot/axios';
// import { articleView } from '@/lib/http';

Vue.use(Vuex, VueCookies);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    /**
     * Login
     */
    init: false,
    isLogged: false,
    name: '',
    nickname: '',
    email: '',
    token: '',
    role: '',

    /** Axios Response Modal Info */
    openModalInfo: false,
    modalInfoContent: 'test',
    urlRedirect: '',

    /** Modal Identity */
    openModalIdentity: false,

    /** System Variables */
    sysVars: [],

    newsPageType: '',

    currentTabName: '',
    /** 審核中, 未通過, 審核通過, 繳費失敗, 實體通路繳費, 繳費成功 */
    // applyStatus: '未通過',
    /** ATM轉帳, 超商代碼繳費 */
    applyPaymentType: '',
  },
  mutations: {
    /**
     * Login
     */
    initLogin(state) {
      VueCookies.keys().forEach((key) => {
        state[key] = VueCookies.get(key);
      });
    },
    initSite(state) {
      state.init = true;
    },
    login(state, data) {
      Object.keys(data).forEach((key) => {
        state[key] = data[key];
      });
      state.isLogged = true;
    },
    logout(state) {
      state.isLogged = false;
      state.name = '';
      state.realname = '';
      state.role = 0;
      state.token = '';
      // state.level = '';
    },

    /** Axios Response Modal Info */
    toggleModalInfo(state, booleanStatus) {
      state.openModalInfo = booleanStatus;
    },
    updateModalInfoContent(state, message) {
      state.modalInfoContent = message;
    },
    assignUrlRedirect(state, url) {
      state.urlRedirect = url;
    },

    /** Modal Identity */
    toggleModalIdentity(state, booleanStatus) {
      state.openModalIdentity = booleanStatus;
    },

    /** System Variables */
    obtainSysVars(state, resArray) {
      state.sysVars = resArray;
    },

    /** News Page Type */
    updateNewsType(state, type) {
      state.newsPageType = type;
    },

    updateTabName(state, tabClicked) {
      state.currentTabName = tabClicked;
    },
    // updateApplyStatus(state, status) {
    //   state.applyStatus = status;
    // },
    updateApplyPaymentType(state, type) {
      state.applyPaymentType = type;
    },
  },
  actions: {
    setSysVars({ commit }, resArray) {
      commit('obtainSysVars', resArray);
    },
    init({ commit }) {
      // console.log('init here', VueCookies.get('token'));
      // const cookieKeys = VueCookies.keys();
      if (VueCookies.isKey('token') && VueCookies.isKey('realname')) {
        commit('initLogin');
        setAxiosHeaders(VueCookies.get('token'));
      }
      setTimeout(() => {
        commit('initSite');
      }, 100);
    },
    login({ commit }, data) {
      commit('login', data);
      Object.keys(data).forEach((key) => {
        VueCookies.set(key, data[key]);
      });
    },
    logout({ commit }) {
      VueCookies.keys().forEach((cookie) => VueCookies.remove(cookie));
      commit('logout');
    },
    logoutBeforeLogin({ commit }) {
      VueCookies.keys().forEach((cookie) => {
        if (cookie !== 'courseInCart') {
          VueCookies.remove(cookie);
        }
      });
      commit('logout');
    },
    openModal({ commit }, payload) {
      if (payload.url) {
        commit('assignUrlRedirect', payload.url);
      }
      commit('updateModalInfoContent', payload.message);
      commit('toggleModalInfo', true);
    },
  },
  modules: {
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token && (state.level * 1 === 1 || state.level * 1 === 2);
    },
    hasToken(state) {
      return !!state.token;
    },
    modalInfoContent(state) {
      return state.modalInfoContent;
    },
    openModalInfo(state) {
      return state.openModalInfo;
    },
    openModalIdentity(state) {
      return state.openModalIdentity;
    },
    /**
     * 時數類別
     */
    hoursType(state) {
      let list = [];
      state.sysVars.forEach((obj) => {
        if (obj) {
          if (obj.keyword === 'hoursType') {
            list = obj.list || [];
          }
        }
      });
      return list;
    },
    /**
     * 活動類別
     */
    activityType(state) {
      let list = [];
      state.sysVars.forEach((obj) => {
        if (obj) {
          if (obj.keyword === 'fractionType') {
            list = obj.list || [];
          }
        }
      });
      return list;
    },
    /**
     * 學會訊息分類
     */
    newsType(state) {
      let list = [];
      state.sysVars.forEach((obj) => {
        if (obj) {
          if (obj.keyword === 'news') {
            list = obj.list || [];
          }
        }
      });
      return list;
    },
    /**
     * 活動類型
     */
    hostType(state) {
      let list = [];
      state.sysVars.forEach((obj) => {
        if (obj) {
          if (obj.keyword === 'hostType') {
            list = obj.list || [];
          }
        }
      });
      return list;
    },
    /**
     * 費用類型
     */
    feeType(state) {
      let list = [];
      state.sysVars.forEach((obj) => {
        if (obj) {
          if (obj.keyword === 'feeType') {
            list = obj.list || [];
          }
        }
      });
      return list;
    },
    /**
     * 餐食類型
     */
    eatType(state) {
      let list = [];
      state.sysVars.forEach((obj) => {
        if (obj) {
          if (obj.keyword === 'eatType') {
            list = obj.list || [];
          }
        }
      });
      return list;
    },
    /**
     * 餐食類型
     */
    foodType(state) {
      let list = [];
      state.sysVars.forEach((obj) => {
        if (obj) {
          if (obj.keyword === 'foodType') {
            list = obj.list || [];
          }
        }
      });
      return list;
    },
  },
});
