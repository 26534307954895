<template>
  <div id="app">
    <Header :componentKey="componentKey" @forceRefresh="forceRefresh" />
    <router-view />
    <Footer />
    <ModalInfo />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ModalInfo from '@/components/ModalInfo.vue';
import { mapActions } from 'vuex';
import { getSysVars } from '@/lib/http';

export default {
  name: 'App',
  data() {
    return {
      componentKey: 0,
    };
  },
  mounted() {
    // getSysVars()
    //   .then((res) => {
    //     console.log('want app init');
    //     this.init(res);
    //   });
    this.init();
    getSysVars()
      .then((res) => {
        this.setSysVars(res);
      });
  },
  methods: {
    ...mapActions([
      'init',
      'setSysVars',
    ]),
    forceRefresh() {
      this.componentKey += 1;
    },
  },
  components: {
    Header,
    Footer,
    ModalInfo,
  },
};
</script>
