import moment from 'moment';

export function monthFormat(sec) {
  const month = 30 * 24 * 60 * 60;
  return (sec / month).toFixed(2);
}

export function timeFormat(sec) {
  if (sec >= 3600) {
    const h = Math.floor(sec / 3600);
    const m = Math.floor((sec % 3600) / 60);
    const s = sec % 60;
    return `${h}時${m}分${s}秒`;
  // eslint-disable-next-line no-else-return
  } else if (sec >= 60) {
    const m = Math.floor(sec / 60);
    const s = sec % 60;
    return `${m}分${s}秒`;
  }
  return `${sec}秒`;
}

export function dayFormat(sec) {
  // if (sec) {
  //   if (sec * 1 >= 86400) {
  //     return `${sec / 86400}天`;
  //   }
  //   return `${sec}秒`;
  // }
  // return '';
  if (sec) {
    return Math.floor(sec / 86400);
  }
  return null;
}

export function expiryFormat(sec) {
  const milliSec = sec * 1000;
  const date = new Date(milliSec);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  return `${y}-${m}-${d}`;
}

export function expiryDate(create, validThru) {
  return moment(create).add({ days: validThru, hours: 8 }).format('YYYY-MM-DD hh:mm:ss');
}

export function timeStampConvert(val) {
  return moment(val, 'X').format('YYYY-MM-DD hh:mm:ss');
}

export function pad(num) {
  return (`0${num}`).slice(-2);
}

export function hhmmss(seconds) {
  let minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}

export function fractionFilter(list, uuidCourse) {
  let target = [];
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    if (item.uuid === uuidCourse) {
      target = item.fraction_list;
    }
  }
  return target;
}

/**
 * 預設時間篩選區間：兩個月前的1號～今天
 */
export function defaultDateChosen() {
  const d = new Date();
  const year = d.getFullYear();
  let month = (d.getMonth() + 1);
  let day = d.getDate();

  const prevYear = (month - 2 > 0) ? year : year - 1;
  let prevMonth = (month - 2 > 0) ? month - 2 : month - 2 + 12;

  if (month < 10) {
    month = `0${month}`;
  }
  if (prevMonth < 10) {
    prevMonth = `0${prevMonth}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }
  return {
    start: [prevYear, prevMonth, '01'].join('-'),
    end: [year, month, day].join('-'),
  };
}

/**
 * 課程活動限制種類
 */
/** 判斷是否於時間區間內 */
export function todayValid(start, end) {
  return moment().isBetween(start, end);
}
/** 判斷是否超過名額 */
export function quotaValid(currentNum, totalNum) {
  return parseInt(currentNum, 10) < parseInt(totalNum, 10);
}
/** 判斷是否符合報名身份 */
export function levelValid(level, prices) {
  return parseInt(prices[level], 10) !== 'NaN';
}
