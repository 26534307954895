<template>
  <header :class="{active: headerStatus}">
    <div class="w1600 contain">

      <!-- Logo -->
      <router-link to="/">
        <img class="logo" src="../statics/img/index/logo.svg" alt="">
      </router-link>

      <!-- 導覽列 -->
      <ul class="main_list h4">
        <li
          class="main_tab heart"
          :class="{active: activeIndex === index, 'mb-apply': mainList.mbApply,}"
          :key="mainList.name"
          @click="setActive(mainList, index); tabClicked('全部')"
          v-for="(mainList, index) in computedMainListArray"
          >
          <router-link :to="mainList.url">
            <h4 class="txt-bold">{{ mainList.kvTitle2 }}</h4>
          </router-link>
          <VueSlideToggle :open="openArray[index].open" :duration="500">
            <ul class="sub_list">
              <li
                v-for="subList in mainList.listArray"
                :key="subList.text"
                @click.stop="tabClicked(subList.text)"
              >
                <!-- <router-link
                  :to="subList.url"
                  :class="{
                    forceDisable: subList.name === '安寧課程'
                  }"
                > -->
                <router-link
                  :to="subList.url"
                >
                  {{ subList.text }}
                </router-link>
              </li>
            </ul>
          </VueSlideToggle>
        </li>
      </ul>
      <ul class="flex-wrap login_box">
        <li class="main_tab">
          <template v-if="isAuthenticated === false">
            <router-link to="/login" class="login_btn">
              <h4 class="txt-bold txt-white">會員登入</h4>
              <i><img class="mb-login" src="@/statics/img/index/icon_login.svg" alt=""></i>
            </router-link>
          </template>
          <template v-else>
            <button type="button" class="login_btn" @click="logoutRequest">
              <h4 class="txt-bold txt-white">會員登出</h4>
              <i><img class="mb-login" src="@/statics/img/index/icon_logout.svg" alt=""></i>
            </button>
          </template>
        </li>
        <!-- <li class="login member_apply pc-apply">
          <router-link to="/application/index">
            <p class="txt-bold txt-light-green">入會申請</p>
          </router-link>
        </li> -->
        <div class="hamburger hamburger--collapse" type="button" @click="headerToggle">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </div>
      </ul>
    </div>

  <!-- Fixed Menu -->
  <div class="fixed_menu">
    <div class="wrap" :class="{active: fixedMenuStatus}">
      <div class="drawer">
        <button @click="fixedMenuToggle">
          <img
            src="../statics/img/about/icon_arrow.svg"
            alt=""
            class="arrow">
          <small>快速連結</small>
        </button>
      </div>
      <ul class="list" :class="{'list-active': fixedMenuStatus}">
        <li v-for="(fixedItem, index) in fixedMenuArray" :key="fixedItem.name">
          <router-link :to="fixedItem.url">
            <small
              @mouseover="imgChange(index)"
              @mouseleave="imgRestore(index)"
            >
              <img :src="require(`../statics/img/index/${fixedItem.currentImg}.svg`)" alt="">
              {{ fixedItem.name }}
            </small>
          </router-link>
        </li>
      </ul>
    </div>
  </div>

  <!-- Modal Verify Identity -->
  <ModalVerifyIdentity />

</header>
</template>

<script>
import {
  AboutKv,
  NewsKv,
  HotlinkKv,
  AccountKv,
  RegisterKv,
} from '@/lib/const';
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';

import { VueSlideToggle } from 'vue-slide-toggle';

import ModalVerifyIdentity from '@/components/ModalVerifyIdentity.vue';
import { setAxiosHeaders } from '@/boot/axios';

export default {
  name: 'Header',
  props: [
    'forceRefresh',
  ],
  data() {
    return {
      varList: [],
      resetTab: '',
      openArray: [
        {
          id: 1,
          open: false,
        },
        {
          id: 2,
          open: false,
        },
        {
          id: 3,
          open: false,
        },
        {
          id: 4,
          open: false,
        },
        {
          id: 5,
          open: false,
        },
        {
          id: 6,
          open: null,
        },

      ],
      headerStatus: false,
      activeIndex: undefined,
      fixedMenuStatus: false,
      fixedMenuArray: [
        // {
        //   id: 1,
        //   name: '積分申請',
        //   url: '/register/point/apply',
        //   currentImg: 'icon_s-1.1',
        //   originImg: 'icon_s-1.1',
        //   hoverImg: 'icon_s-1.2',
        // },
        // {
        //   id: 2,
        //   name: '會員查詢',
        //   url: '/account/search/fee',
        //   currentImg: 'icon_s-2.1',
        //   originImg: 'icon_s-2.1',
        //   hoverImg: 'icon_s-2.2',
        // },
        {
          id: 3,
          name: '線上課程',
          url: '/register/online/index',
          currentImg: 'icon_s-3.1',
          originImg: 'icon_s-3.1',
          hoverImg: 'icon_s-3.2',
        },
      ],
      mainListArray: [
        {
          id: 1,
          ...AboutKv.pageHeader,
        },
        {
          id: 2,
          ...NewsKv.pageHeader,
        },
        {
          id: 3,
          ...RegisterKv.pageHeader,
        },
        {
          id: 4,
          ...HotlinkKv.pageHeader,
        },
        {
          id: 5,
          ...AccountKv.pageHeader,
        },
        // {
        //   id: 6,
        //   kvTitle2: '入會申請',
        //   url: '/application/index',
        //   subListArray: null,
        // },
      ],
    };
  },
  computed: {
    ...mapState([
      'level',
    ]),
    ...mapGetters([
      'isAuthenticated',
      'newsType',
    ]),
    /**
     * 學會訊息相關頁籤重組結構
     */
    computedPageHeader() {
      const varListUpdate = this.newsType.map((item) => ({
        ...item,
        text: item.name,
        url: `/news/${item.name}`,
      }));
      return {
        ...NewsKv.pageHeader,
        url: '/news/全部',
        listArray: [
          {
            id: '',
            url: '/news/全部',
            text: '全部',
          },
          ...varListUpdate,
        ],
      };
    },
    /**
     * 會員專區重組(待刪除功能)：相關查詢其餘頁籤未開發，ˊ除了level-1會員外先隱藏
     */
    computedListArray() {
      if (AccountKv) {
        const copyListArray = [];
        AccountKv.pageHeader.listArray.forEach((item) => {
          console.log(this.$cookies.get('level'));
          if (item.text === '相關查詢' && parseInt(this.$cookies.get('level'), 10) !== 1) {
            return;
          }
          copyListArray.push(item);
        });
        return { ...AccountKv.pageHeader, listArray: copyListArray };
      }
      return {};
    },
    computedMainListArray() {
      for (let i = 0; i < this.mainListArray.length; i += 1) {
        const arrItem = this.mainListArray[i];

        arrItem.mbApply = (arrItem.kvTitle2 === '入會申請');
        arrItem.requireLogin = (this.isAuthenticated === false) ? (arrItem.kvTitle2 === '會員專區') : false;

        /** 學會訊息的子頁籤改為後台創建 */
        if (arrItem.kvTitle2 === '學會訊息') {
          arrItem.url = this.computedPageHeader.url;
          arrItem.listArray = this.computedPageHeader.listArray;
        }

        /** 會員專區重組(待刪除功能)：相關查詢其餘頁籤未開發，除了level-1會員外先隱藏 */
        if (arrItem.kvTitle2 === '會員專區') {
          // arrItem.listArray = this.computedListArray.listArray;
          arrItem.listArray = [];
        }
      }

      return this.mainListArray.filter((mainList) => mainList.requireLogin === false);
    },
  },
  methods: {
    ...mapMutations([
      'updateTabName',
      'updateNewsType',
    ]),
    ...mapActions([
      'init',
      'logout',
    ]),
    imgChange(index) {
      this.fixedMenuArray[index].currentImg = this.fixedMenuArray[index].hoverImg;
    },
    imgRestore(index) {
      this.fixedMenuArray[index].currentImg = this.fixedMenuArray[index].originImg;
    },
    fixedMenuToggle() {
      this.fixedMenuStatus = !this.fixedMenuStatus;
    },
    headerToggle() {
      this.headerStatus = !this.headerStatus;
    },
    setActive(item, index) {
      if (item.name === '入會申請') return;
      this.openArray[index].open = !this.openArray[index].open;
      this.updateTabName('');
    },
    tabClicked(tabName) {
      this.updateTabName(tabName);
      this.updateNewsType(tabName);
    },
    logoutRequest() {
      this.logout()
        .then(() => {
          this.$router.push({ path: '/login' });
          setAxiosHeaders(null);
        });
    },
  },
  components: {
    VueSlideToggle,
    ModalVerifyIdentity,
  },
};
</script>
