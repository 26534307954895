/* eslint-disable no-param-reassign */
import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
// eslint-disable-next-line no-unused-vars
// import store from '@/store';
import { API_URL } from '@/lib/config';

let AUTH_TOKEN = null;

Vue.prototype.$axios = axios;

export function toFormData(data, qstr = false) {
  // console.log('course apply', data);
  if (qstr) return qs.stringify(data);

  const form = new FormData();
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'object' && data[key] !== null) {
      Object.keys(data[key]).forEach((childKey) => {
        form.append(`${key}[${childKey}]`, data[key][childKey]);
      });
    } else {
      form.append(key, data[key]);
    }
  });
  // eslint-disable-next-line no-restricted-syntax
  // for (const pair of form.entries()) {
  //   console.log(`${pair[0]}, ${pair[1]}`);
  // }
  return form;
}

export function setAxiosHeaders(token = null) {
  AUTH_TOKEN = token;
}

export function request(
  {
    // eslint-disable-next-line no-unused-vars
    url = '', method = 'get', data = false, token = AUTH_TOKEN, headers = {}, params = {}, binary = false,
  } = {},
) {
  delete axios.defaults.headers.common.Authorization;
  if (token !== null) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }
  // headers = {
  //   Authorization:
  //   'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ
  // .eyJpc3MiOiJlZDAzM2I1NDhiMTg4YmM0ZjU0MmQ3ZTc5YjNlYjIzZTMxNDc4YmVhOGE2
  // YmEwODZhNzFkZTQ4NmQ4ZTAwMjIxIiwic3ViIjoyLCJleHAiOjE2MDk1MTM1OTh9
  // .c13o1YafgGjc3zUlhnxeWwWrRMZaJUZ5dVzypBacd3QP6RPQybfF9iEtSNqR54eI3I24mi-UFlU20DEw-ZEHhA',
  // };

  let req = {
    // baseURL: process.env.API,
    baseURL: API_URL,
    url,
    method,
    headers,
    Accept: 'application/json, text/plain, */*',
    mode: 'no-cors',
    params,
  };

  if (['put', 'post', 'patch'].indexOf(method) !== -1 && data !== false) {
    if (binary) {
      headers = {
        ...headers,
        'Content-Type': 'multipart/form-data',
      };
    } else {
      headers = {
        ...headers,
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Content-Type': 'application/json',
      };
    }
    req = { ...req, data, headers };
  }

  console.info('req', req);

  return axios(req)
    .then((response) => {
      console.log('response', response);
      return response;
    })
    .catch((err) => {
      console.info(err);
    });
}
