<template>
  <div>
    <div class="w1100 page_header_space">
      <h3 class="txt-bold">聯絡我們</h3>
      <img src="@/statics/img/index/icon_title2.svg" alt="">
    </div>

    <section id="contact">
      <div class="bg">
        <img src="@/statics/img/about/bg.png" alt="">
      </div>
      <div class="container w1100">
        <div class="col50">
          <div class="name">
            <p class="txt-light_green">Association of Home Care</p>
            <h2 class="txt-dark_green">社團法人台灣居家醫療醫學會</h2>
          </div>
          <div class="content">
            <h4><img src="@/statics/img/about/icon_call.svg" alt="">聯絡電話 04-24360305</h4>
            <h4><img src="@/statics/img/about/icon_fax.svg" alt="">傳真號碼 04-24362334</h4>
            <h4>
              <img src="@/statics/img/about/icon_mail.svg" alt="">電子信箱 loveminwoo15@yahoo.com.tw
            </h4>
            <h4><img src="@/statics/img/about/icon_location.svg" alt="">台中市北屯區環中東路二段255號4樓</h4>
          </div>
        </div>
        <div class="col50">
          <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3639.888987120959!2d120.71895881536797!3d24.175625778314792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3469181878813647%3A0x4a06330b7ee56d43!2zNDA25Y-w5Lit5biC5YyX5bGv5Y2A55Kw5Lit5p2x6Lev5LqM5q61MjU16JmfNA!5e0!3m2!1szh-TW!2stw!4v1601965476582!5m2!1szh-TW!2stw"
            width="1200"
            height="684"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0">
          </iframe>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutContact',
  data() {
    return {

    };
  },
};
</script>
