<template>
  <div
    id="modal_container-info"
    class="modal_container"
    :class="{active: openModalInfo}"
    @click="closeModal"
  >
    <div class="modal txt-center" @click.stop>
      <div class="close_btn h2" @click="closeModal"><span>×</span></div>
      <div class="modal_content mg-b-50">
        <p class="txt-bold" v-html="modalInfoContent"></p>
      </div>
      <div class="modal_confirm">
        <button type="button" class="btn p" @click="closeModal">
          確認
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'ModalInfo',
  computed: {
    ...mapState([
      'urlRedirect',
    ]),
    ...mapGetters([
      'openModalInfo',
      'modalInfoContent',
    ]),
  },
  methods: {
    ...mapMutations([
      'toggleModalInfo',
    ]),
    closeModal() {
      this.toggleModalInfo(false);
      if (this.urlRedirect) {
        this.$router.push({ path: this.urlRedirect });
      }
    },
  },
};
</script>
