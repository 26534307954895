import aboutBanner from '@/statics/img/about/banner.png';
import newsBanner from '@/statics/img/banner/news.png';
import hotlinkBanner from '@/statics/img/banner/hotlink.png';
import accountBanner from '@/statics/img/banner/account.png';

export const AboutKv = {
  pageHeader: {
    name: 'about',
    kvImg: aboutBanner,
    kvTitle1: 'ABOUT US',
    kvTitle2: '認識學會',
    url: '/about',
    listArray: [
      {
        id: 1,
        url: '/about/index',
        text: '沿革',
      },
      {
        id: 2,
        url: '/about/association',
        text: '章程',
      },
      {
        id: 3,
        url: '/about/chairMan',
        text: '理事長的話',
      },
      {
        id: 4,
        url: '/about/memberList',
        text: '監理事名單',
      },
      {
        id: 5,
        url: '/about/according',
        text: '甄審辦法',
      },
      {
        id: 6,
        url: '/about/contact',
        text: '聯絡我們',
      },
    ],
  },
};

export const NewsKv = {
  pageHeader: {
    name: 'news',
    kvImg: newsBanner,
    kvTitle1: 'IMFORMATION',
    kvTitle2: '學會訊息',
    url: '/news',
    listArray: [
      {
        id: 1,
        url: '/news/index',
        text: '全部',
      },
      {
        id: 2,
        url: '/news/relative',
        text: '相關公文',
      },
      {
        id: 3,
        url: '/news/paper',
        text: '論文刊物',
      },
      {
        id: 4,
        url: '/news/health',
        text: '健保資訊',
      },
      {
        id: 5,
        url: '/news/focus',
        text: '新聞焦點',
      },
      {
        id: 6,
        url: '/news/activity',
        text: '活動花絮',
      },
    ],
  },
};

export const HotlinkKv = {
  pageHeader: {
    name: 'hotlink',
    kvImg: hotlinkBanner,
    kvTitle1: 'HOT LINKS',
    kvTitle2: '快速連結',
    url: '/hotlink',
    listArray: [
      {
        id: 1,
        url: '/hotlink/relative',
        text: '相關單位',
      },
      {
        id: 2,
        url: '/hotlink/medicine',
        text: '藥庫查詢',
      },
    ],
  },
};

export const AccountKv = {
  pageHeader: {
    name: 'account',
    kvImg: accountBanner,
    kvTitle1: 'ACCOUNT',
    kvTitle2: '會員專區',
    // url: '/account',
    url: '/account/index',
    listArray: [
      {
        id: 1,
        url: '/account/index',
        text: '會員資料變更',
      },
      // {
      //   id: 2,
      //   url: '/account/apply',
      //   text: '相關申請',
      // },
      {
        id: 3,
        // url: '/account/search',
        url: '/account/search/points',
        text: '相關查詢',
      },
      // {
      //   id: 4,
      //   url: '/account/manual',
      //   text: '醫師手冊',
      // },
      {
        id: 5,
        url: '/register/record',
        text: '活動紀錄',
      },
    ],
  },
};

export const AccountApplySubHeader = {
  pageSubHeader: {
    parent: 'account',
    name: 'apply',
    listArray: [
      {
        id: 1,
        url: '/account/apply/profession',
        text: '專科申請',
      },
      {
        id: 2,
        url: '/account/apply/extension',
        text: '展延申請',
      },
    ],
  },
};

export const AccountSearchSubHeader = {
  pageSubHeader: {
    parent: 'account',
    name: 'search',
    listArray: [
      // {
      //   id: 1,
      //   url: '/account/search/fee',
      //   text: '費用查詢',
      // },
      {
        id: 2,
        url: '/account/search/points',
        text: '積分查詢',
      },
      // {
      //   id: 3,
      //   url: '/account/search/profession',
      //   text: '專科申請查詢',
      // },
      // {
      //   id: 4,
      //   url: '/account/search/extension',
      //   text: '展延申請查詢',
      // },
    ],
  },
};

export const RegisterKv = {
  pageHeader: {
    name: 'register',
    kvImg: accountBanner,
    kvTitle1: 'COURSES & ACTIVITIES',
    kvTitle2: '課程活動',
    // url: '/register',
    url: '/register/online',
    listArray: [
      // {
      //   id: 1,
      //   url: '/register/calender',
      //   text: '行事曆',
      // },
      // {
      //   id: 2,
      //   url: '/register/event',
      //   text: '學術活動',
      // },
      {
        id: 3,
        url: '/register/online',
        text: '線上課程',
      },
      {
        id: 4,
        url: '/register/online/mycourse/info?course_id=5fe9501830a05',
        text: '安寧課程',
      },
      // {
      //   id: 5,
      //   url: '/register/annual',
      //   text: '年會報名',
      // },
      {
        id: 6,
        url: '/register/record',
        text: '活動紀錄',
      },
      // {
      //   id: 7,
      //   url: '/register/point',
      //   text: '積分申請',
      // },
    ],
  },
};

export const RegisterOnlineSubHeader = {
  pageSubHeader: {
    parent: 'register',
    name: 'online',
    listArray: [
      {
        id: 1,
        url: '/register/online/index',
        text: '課程總覽',
      },
      {
        id: 2,
        url: '/register/online/grocery',
        text: '課程訂購清單',
      },
      {
        id: 3,
        url: '/register/online/mycourse',
        text: '我的課程',
      },
    ],
  },
};

export const RegisterEventSubHeader = {
  pageSubHeader: {
    parent: 'register',
    name: 'event',
    listArray: [
      {
        id: 1,
        url: '/register/event/info',
        text: '活動資訊',
      },
      {
        id: 2,
        url: '/register/event/member',
        text: '會員報名',
      },
      {
        id: 3,
        url: '/register/event/nonMember',
        text: '非會員報名',
      },
    ],
  },
};

export const RegisterAnnualSubHeader = {
  pageSubHeader: {
    parent: 'register',
    name: 'annual',
    listArray: [
      {
        id: 1,
        url: '/register/annual/info',
        text: '年會資訊',
      },
      // {
      //   id: 2,
      //   url: '/register/annual/member',
      //   text: '會員報名',
      // },
      // {
      //   id: 3,
      //   url: '/register/annual/nonMember',
      //   text: '非會員報名',
      // },
    ],
  },
};

export const RegisterPointSubHeader = {
  pageSubHeader: {
    parent: 'register',
    name: 'point',
    listArray: [
      {
        id: 1,
        url: '/register/point/apply',
        text: '積分申請',
      },
      {
        id: 2,
        url: '/register/point/search',
        text: '積分申請查詢',
      },
    ],
  },
};

export const fractionsName = {
  fractionsNameArr: [
    '長照服務人員',
    '醫事人員',
    '護理人員',
  ],
};

export const memberLevel = {
  memberLevelArr: [
    '專科醫師',
    '會員醫師',
    '非會員醫師',
    '護理人員',
  ],
};
